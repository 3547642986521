import './App.css';

import store from './store';
import { loadSeries, } from './actions/index'

import Home from './containers/home'



import { Switch, Route, BrowserRouter, NavLink } from 'react-router-dom';


const Header = () => (
  <nav className='navbar navbar-light bg-dark'>
    <ul className="d-flex mx-auto mb-0">
      <li className='nav-item'><NavLink to="/" exact activeClassName="active" className='nav-link'>Home</NavLink></li>
      <li className='nav-item'><NavLink to="/stuff" activeClassName="active" className='nav-link'>Stuff</NavLink></li>
    </ul>
  </nav>
)

const Main = () => (
  <div className='row justify-content-center mx-0 mt-3'>
    <div className='col-12 col-md-10 col-lg-8'>
      <Switch >
        <Route exact path='/' component={Home} />
      </Switch>
    </div>
  </div>


)


const App = () => {

  store.dispatch(loadSeries())



  return (
    <BrowserRouter>
      <Header />
      <Main />
    </BrowserRouter>
  );
};

export default App;
