class SeriesAPI {

    constructor() {
        this.series = [
            { id: 1, name: "Rick and Morty", imdb: "tt2861424", img: 'rick_and_morty.jpg', genre: 'Animation, Adventure', desc: 'An animated series that follows the exploits of a super scientist and his not-so-bright grandson.' },
            { id: 2, name: "Tiger King", imdb: 'tt11823076', img: 'tiger_king.jpg', genre: 'Documentary, Crime', desc: 'A rivalry between big cat eccentrics takes a dark turn when Joe Exotic, a controversial animal park boss, is caught in a murder-for-hire plot.' },
            { id: 3, name: "The Big Bang Theory ", imdb: 'tt0898266', img: 'the_big_bang_theory.jpg', genre: 'Comedy, Romance ', desc: 'A woman who moves into an apartment across the hall from two brilliant but socially awkward physicists shows them how little they know about life outside of the laboratory.' },
            { id: 4, name: "The Walking Dead", imdb: 'tt1520211', img: 'the_walking_dead.jpg', genre: 'Drama, Horror, Thriller', desc: 'Sheriff Deputy Rick Grimes wakes up from a coma to learn the world is in ruins and must lead a group of survivors to stay alive.' },
            { id: 5, name: 'Riverdale', imdb: 'tt5420376', img: 'riverdale.jpg', genre: 'Crime, Mystery', desc: 'While navigating the troubled waters of romance, school and family, Archie and his gang become entangled in dark Riverdale mysteries.' },
            { id: 6, name: 'De Twaalf', imdb: 'tt7605396', img: 'de_twaalf.jpg', genre: 'Drama, Thriller', desc: 'Twelve ordinary people are called for jury duty for a murder case as traumatizing as it is controversial in which a woman stands trial for killing her own blood.' },
            { id: 7, name: 'The Handmaid`s Tale', imdb: 'tt5834204', img: 'the_handmaids_tale.jpg', genre: 'Drama, Sci-Fi', desc: 'Set in a dystopian future, a woman is forced to live as a concubine under a fundamentalist theocratic dictatorship.' },
            { id: 8, name: 'Breaking Bad ', imdb: 'tt0903747', img: 'breaking_bad.jpg', genre: 'Crime, Drama', desc: 'A high school chemistry teacher diagnosed with inoperable lung cancer turns to manufacturing and selling methamphetamine in order to secure his family`s future.' },
            { id: 9, name: 'Friends', imdb: 'tt0108778', img: 'friends.jpg', genre: 'Comedy, Romance', desc: 'Follows the personal and professional lives of six twenty to thirty-something-year-old friends living in Manhattan.' },
            { id: 10, name: 'Black Mirror', imdb: 'tt2085059', img: 'black_mirror.jpg', genre: 'Drama, Sci-Fi', desc: 'An anthology series exploring a twisted, high-tech multiverse where humanity`s greatest innovations and darkest instincts collide.' },
            { id: 11, name: 'The 100', imdb: 'tt2661044', img: 'the_100.jpg', genre: 'Mystery, Sci-Fi', desc: 'Set ninety-seven years after a nuclear war has destroyed civilization, when a spaceship housing humanity`s lone survivors sends one hundred juvenile delinquents back to Earth, in hopes of possibly re-populating the planet.' },
            { id: 12, name: 'Game of Thrones', imdb: 'tt0944947', img: 'got.jpg', genre: 'Action, Adventure', desc: 'Nine noble families fight for control over the lands of Westeros, while an ancient enemy returns after being dormant for millennia.' },
            { id: 13, name: 'Eigen Kweek', imdb: 'tt3313722', img: 'eigen_kweek.jpg', genre: 'Drama, Crime', desc: 'a farmer loses all his money and decides to grow weed to compensate for the losses.' },
            { id: 14, name: 'The Office', imdb: 'tt0386676', img: 'the_office.jpg', genre: 'Comedy', desc: 'A mockumentary on a group of typical office workers, where the workday consists of ego clashes, inappropriate behavior, and tedium.' },
            { id: 15, name: 'South Park', imdb: 'tt0121955', img: 'south_park.jpg', genre: 'Animation, Comedy', desc: 'Follows the misadventures of four irreverent grade-schoolers in the quiet, dysfunctional town of South Park, Colorado.' },
            { id: 16, name: 'The Crown', imdb: 'tt4786824', img: 'the_crown.jpg', genre: 'Drama, History', desc: 'Follows the political rivalries and romance of Queen Elizabeth II reign and the events that shaped the second half of the twentieth century.' },
            { id: 17, name: 'Chernobyl ', imdb: 'tt7366338', img: 'chernobyl.jpg', genre: 'Drama, History', desc: 'In April 1986, an explosion at the Chernobyl nuclear power plant in the Union of Soviet Socialist Republics becomes one of the worlds worst man-made catastrophes.' },
            { id: 18, name: 'Stranger Things', imdb: 'tt4574334', img: 'stranger_things.jpg', genre: 'Drama, Fantasy', desc: 'When a young boy disappears, his mother, a police chief and his friends must confront terrifying supernatural forces in order to get him back.' },
            { id: 19, name: 'La Casa de Papel', imdb: 'tt6468322', img: 'casa_de_papel.jpg', genre: 'Action, Crime', desc: 'An unusual group of robbers attempt to carry out the most perfect robbery in Spanish history - stealing 2.4 billion euros from the Royal Mint of Spain.' },
            { id: 20, name: 'Last Week Tonight', imdb: 'tt3530232', img: 'last_week_tonight.jpg', genre: 'Comedy, News', desc: 'Former Daily Show host and correspondent John Oliver brings his persona to this weekly news satire program.' },
            { id: 21, name: '13 Reasons Why', imdb: 'tt1837492', img: '13.jpg', genre: 'Drama, Mystery', desc: 'Follows teenager Clay Jensen, in his quest to uncover the story behind his classmate and crush, Hannah, and her decision to end her life.' }
        ];
    }

    all() {
        return this.series;
    }

    get(id) {
        return this.series.find((s) => s.id === id);
    }

}

export default SeriesAPI;
