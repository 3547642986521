import { combineReducers } from 'redux';

import series from './series_reducer';
import user from './user_reducer';
import counter from './counter_reducer';


const reducers = combineReducers({
    series,
    user,
    counter
});

export default reducers;


