
import React, { Component } from 'react';

class SerieForm extends Component {
    constructor(props) {
        super(props)

        this.handleWatch = this.handleWatch.bind(this)
        this.handleScore = this.handleScore.bind(this)
        this.handleCounter = this.handleCounter.bind(this)

    }

    handleCounter() {
        this.props.onUpdateCounter()

    }

    handleScore(event) {
        let serie = this.props.series[this.props.counter - 1]
        serie.score = event.target.value;
        this.props.onUpdateSerie(serie)
    }

    handleWatch(bool) {
        let serie = this.props.series[this.props.counter - 1]
        serie.seen = bool;
        this.props.onUpdateSerie(serie)
    }

    render() {
        // console.log(this.props.series[this.props.counter - 1].seen)
        // console.log(this.props.series[this.props.counter - 1].score)

        let btn1 = ''
        let btn2 = ''
        let score = false
        let next = false
        if (this.props.series[this.props.counter - 1].seen === undefined) {
            btn1 = 'btn-outline-primary';
            btn2 = 'btn-outline-primary';
        } else if (this.props.series[this.props.counter - 1].seen == true) {
            btn1 = 'btn-primary';
            btn2 = 'btn-outline-primary';
            score = true
        } else {
            btn2 = 'btn-primary';
            btn1 = 'btn-outline-primary';
            next = true
        }

        let scorebar = ''

        if (score) {

            if (this.props.series[this.props.counter - 1].score !== undefined) {
                next = true
                scorebar = (
                    <p>
                        <input type='range' min='0' max='10' step='1' value={this.props.series[this.props.counter - 1].score} onChange={this.handleScore}></input>
                        <span className='pl-3 text-success font-weight-bold'>{this.props.series[this.props.counter - 1].score}</span>
                    </p>
                )
            } else {
                scorebar = (
                    <p>
                        <input type='range' min='0' max='10' step='1' value={this.props.series[this.props.counter - 1].score} onChange={this.handleScore}></input>
                        <span className='pl-3 text-danger'>Nog geen score gegeven</span>
                    </p>
                )
            }
        }

        let done = <button className='btn btn-outline-success disabled float-right' disabled>Volgende</button>
        if (next) {
            done = <button className='btn btn-success float-right' onClick={() => this.handleCounter()}>Volgende</button>
        }


        return (
            <div className='alert-primary rounded p-4 row'>
                <div className='col-4'>
                    <img src={'images/' + this.props.series[this.props.counter - 1].img} className='img-fluid' />
                </div>
                <div className='col-8'>
                    <h1 className='title'>{this.props.series[this.props.counter - 1].name}</h1>
                    <h2 className='genre h4'>{this.props.series[this.props.counter - 1].genre}
                        <a href={'https://www.imdb.com/title/' + this.props.series[this.props.counter - 1].imdb} target='_blank'>
                            <img src='images/imdb.png' width='60' className='pl-3' />
                        </a>
                    </h2>

                    <p className='desc pt-2'>{this.props.series[this.props.counter - 1].desc}</p>

                    <div>
                        <button className={'btn mr-3 ' + btn1}
                            onClick={() => this.handleWatch(true)}>
                            Bekeken
                        </button>


                        <button className={'btn mr-3 ' + btn2}
                            onClick={() => this.handleWatch(false)}>
                            Niet bekeken
                         </button>

                    </div>


                    <div className='pt-3'>
                        {scorebar}
                    </div>


                    <div className='pt-3'>
                        {done}
                    </div>

                </div>
            </div>
        )
    }
}

export default SerieForm