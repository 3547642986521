import React, { Component } from 'react'


class StartForm extends Component {
    constructor(props) {
        super(props);

        this.state = ({ voornaam: '', achternaam: '' })

        this.handleVNameChange = this.handleVNameChange.bind(this)
        this.handleLNameChange = this.handleLNameChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

    }


    handleVNameChange(event) {
        this.setState({ voornaam: event.target.value })
    }

    handleLNameChange(event) {
        this.setState({ achternaam: event.target.value })
    }

    render() {
        return (
            <div className='alert-primary rounded p-4'>
                <h1 className='text-center display-4 mb-2'>Enquête</h1>
                <p className='text-center'>Deze enquête gaat over tv-series,<br /> welke u heeft bekeken en hoe u deze beoordeeld  </p>
                <form className='text-center form' onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label>Voornaam
                            <input type="text" className="form-control" placeholder="voornaam"
                                value={this.state.voornaam}
                                onChange={this.handleVNameChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>Achternaam
                            <input type="text" className="form-control" placeholder="achternaam"
                                value={this.state.achternaam}
                                onChange={this.handleLNameChange}
                            />

                        </label>
                    </div>


                    <small>Deze gegevens worden enkel voor educatieve context gebruikt</small>
                    <br />
                    <br />
                    <button type='submit' className='btn btn-large btn-primary'>Verder</button>
                </form>
            </div>

        )

    }


    handleSubmit(e) {
        e.preventDefault()
        this.props.onUpdateUser(
            {
                achternaam: this.state.achternaam,
                voornaam: this.state.voornaam
            }
        )
        this.props.onUpdateCounter()
        this.setState({ achternaam: '', voornaam: '' })

    }




}


export default StartForm;