import { LOAD_SERIES, UPDATE_SERIE } from '../actions/index';
import SeriesAPI from '../apis/my_api'
let api = new SeriesAPI()

const serieReducer = (state = [], action) => {
    let newState = [...state];
    switch (action.type) {

        case LOAD_SERIES:

            return api.all();

        case UPDATE_SERIE:

            return state.map(serie => {
                if (serie.id !== action.serie.id) {
                    return serie
                }

                return {
                    ...serie,
                    seen: action.serie.seen,
                    score: action.serie.score
                }
            })

        default:
            return newState;
    }
};

export default serieReducer;

