
/*
* action types
*/

export const LOAD_SERIES = 'LOAD_SERIES';
export const UPDATE_SERIE = 'UPDATE_SERIE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_COUNTER = 'UPDATE_COUNTER'

/*
 * action creators REDUX
 */

export function loadSeries() {
    return { type: LOAD_SERIES };
}

export function updateSerie(serie) {
    return { type: UPDATE_SERIE, serie }
}

export function updateUser(user) {
    return { type: UPDATE_USER, user };
}

export function updateCounter() {
    return { type: UPDATE_COUNTER }
}
