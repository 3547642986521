import React, { Component } from 'react'
import $ from 'jquery'


class DoneForm extends Component {
    constructor(props) {
        super(props);
        this.state = { send: false }

        this.handleSend = this.handleSend.bind(this)
        this.handleReload = this.handleReload.bind(this)

    }

    handleReload() {
        window.location.reload();
    }

    handleSend() {
        console.log('send')

        let series = this.props.series.map((item) => {
            return {
                id: item.id,
                name: item.name,
                seen: item.seen,
                score: item.score
            }
        })


        let answer = {
            user: this.props.user,
            series: series
        }

        $.ajax({
            url: 'https://series.robvl.be/data/index.php',
            type: 'post',
            data: { answer: JSON.stringify(answer) },
            success: function (data) {
                console.log(data)
                this.setState({ send: true })
            }.bind(this),
            error: function (xhr, status, err) {
                console.log(err)
            }.bind(this)
        });



    }



    render() {
        let show = '';
        if (!this.state.send) {
            show = <p className='text-center mt-3'> <button className='btn btn-outline-success' onClick={() => this.handleSend()}>Verzenden</button></p>
        } else {
            show = (
                <div>
                    <p className='text-center mt-3'> <button disabled className='btn btn-success disabled' onClick={() => this.handleSend()}>Voltooid</button></p>
                    <p className='text-center mt-3'> <button className='btn btn-warning ' onClick={() => this.handleReload()}>Opnieuw invullen</button></p>
                </div>
            )

        }
        return (
            <div className='alert-primary rounded p-4 text-center'>
                <h1 className='display-4'>Bedankt!</h1>
                {show}
            </div>

        )

    }



}


export default DoneForm;