import React, { Component } from 'react';
import { connect } from 'react-redux';


import { loadSeries, updateSerie, updateUser, updateCounter } from '../actions/index'


import StartForm from '../components/start_form';
import SerieForm from '../components/serie_form';
import Doneform from '../components/done_form';



class HomeContainer extends Component {
    constructor(props) {
        super();
    }

    componentDidMount() {


    }

    render() {

        // console.log(this.props)
        if (this.props.counter === 0) {
            return (
                <StartForm {...this.props} />
            );
        } else if (this.props.counter === 22) {
            return (
                <Doneform {...this.props} />
            );
        } else if (this.props.counter > 22) {
            return (
                <SerieForm {...this.props} />
            );
        } else {
            return (
                <SerieForm {...this.props} />
            );
        }

    }
}


const mapStateToProps = (state) => {
    // mapStateToProps: mapping of the props with the state
    return {
        series: state.series,
        user: state.user,
        counter: state.counter
    }
};

const mapDispatchToProps = (dispatch) => {
    // mapDispatchToProps: mapping of the eventhandlers with dispatch
    return {
        onLoadSerie: () => {
            dispatch(loadSeries())
        },
        onUpdateSerie: (i) => {
            dispatch(updateSerie(i))
        },
        onUpdateUser: (i) => {
            dispatch(updateUser(i))
        },
        onUpdateCounter: () => {
            dispatch(updateCounter())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);